@media screen and (min-width: 721px) {
  .ActivitiesHead {
    color: white;
    text-align: center;
    padding-top: 10vw;
    margin: -8vw 5vw 4vw 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ActivitiesHead div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* width: 65vw; */
  }

  .ActivitiesHead div div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 65vw; */
  }

  .ActivitiesHead h1 {
    width: 100%;
    text-align: center;
    font-size: 3.3vw;
    font-weight: 500;
  }

  .ActivitiesHead p {
    display: inline;
    font-size: 1.5vw;
    font-weight: 300;
    margin-top: 5vw;
    text-align: justify;
    /* border: 1px solid red; */
  }

  .ActivitiesHeadImg {
    width: 22vw;
    /* margin-left: 2vw; */
  }

  .ActivitiesHeadImgPhone {
    display: none;
  }

  .ActivitiesTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5vw 5vw 8vw 5vw;
    border-radius: 0.2vw;
    background: rgba(205, 251, 253, 1);
  }

  .ActivitiesTop img {
    width: 100%;
    border-radius: 0.1vw;
    border-radius: 0.2vw 0.2vw 0vw 0vw;
  }

  .ActivitiesTop p {
    font-size: 1.55vw;
    font-weight: bold;
    margin: 2.3vw 1.9vw;
    text-align: justify;
  }

  .ActivitiesContentOut {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 5vw auto 0vw auto;
  }

  .ActivitiesContent {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 47.5%;
    padding: 1.8vw 2.8vw 2.8vw 2.8vw;
    border-radius: 0.2vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
    margin-bottom: 4.5vw;
  }

  .ActivitiesContent span {
    /* border: 1px solid red; */
    font-size: 2.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 1.8vw;
  }

  .AchievementImg {
    width: 100%;
    height: 19vw;
    border-radius: 0.2vw;
  }

  .ActivitiesContent p {
    color: white;
    font-size: 1.52vw;
    font-weight: 500;
    text-align: center;
    margin-top: 2.8vw;
  }


  .ProjectOut {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0vw auto 4vw auto;
  }

  .ProjectContainer {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 31%;
    padding: 1.8vw 2.8vw 2.8vw 2.8vw;
    border-radius: 0.2vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
    margin-bottom: 1vw;
  }

  .ProjectContainer span {
    /* border: 1px solid red; */
    display: block;
    width: 100%;
    text-align: center;
    font-size: 2.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 1.8vw;
  }

  .ProjectContainer img {
    width: 100%;
    /* height: 19vw; */
    border-radius: 0.2vw;
  }

  .ProjectContainer p {
    color: white;
    font-size: 1.52vw;
    font-weight: 500;
    text-align: center;
    margin-top: 2.8vw;
  }




  /* .ProjectContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 90%;
    border-radius: 0.2vw;
    margin: 5vw auto 5vw auto;
    padding: 1.8vw 2.8vw 2.8vw 2.8vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
  }

  .ProjectContainer span {
    display: block;
    width: 100%;
    font-size: 2.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 2vw;
  }

  .IPASSliderImgs {
    width: 31vw;
    height: 100%;
    border-radius: 0.2vw;
  }

  .ProjectContainer p {
    color: white;
    font-size: 1.52vw;
    font-weight: 500;
    text-align: justify;
    width: 50vw;
    position: relative;
  } */

  /* .FillProjectSpace {
    display: none;
  } */
}