@media screen and (max-width: 720px) {
  .ActivitiesHead {
    color: white;
    text-align: center;
    padding-top: 28vw;
    margin: -20vw 10vw 10vw 10vw;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .ActivitiesHead div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ActivitiesHead h1 {
    /* border: 1px solid red; */
    font-size: 6vw;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .ActivitiesHead p {
    font-size: 4vw;
    font-weight: 300;
    margin-top: 6vw;
    text-align: justify;
    /* border: 1px solid red; */
  }

  .ActivitiesHeadImg {
    display: none;
  }

  .ActivitiesHeadImgPhone {
    width: 100%;
    margin-top: 10vw;
  }

  .ActivitiesTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10vw 5vw 10vw 5vw;
    border-radius: 0.6vw;
    background: rgba(205, 251, 253, 1);
  }

  .ActivitiesTop img {
    width: 100%;
    border-radius: 0.6vw 0.6vw 0vw 0vw;
  }

  .ActivitiesTop p {
    font-size: 3.5vw;
    font-weight: bold;
    margin: 4vw 4vw;
    text-align: justify;
  }

  .ActivitiesContentOut {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 20vw auto 0vw auto;
  }

  .ActivitiesContent {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3.8vw 4.8vw 4.8vw 4.8vw;
    border-radius: 0.2vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
    margin-bottom: 10vw;
  }

  .ActivitiesContent span {
    /* border: 1px solid red; */
    font-size: 5.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 3.8vw;
  }

  .AchievementImg {
    width: 100%;
    height: 40vw;
    border-radius: 0.6vw;
  }

  .ActivitiesContent p {
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
    text-align: center;
    margin-top: 4.8vw;
  }

  /* .ProjectHead {
    margin-top: -5vw;
    margin-bottom: -10vw;
  } */

  .ProjectOut {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0vw auto 1vw auto;
  }

  .ProjectContainer {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3.8vw 4.8vw 4.8vw 4.8vw;
    border-radius: 0.2vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
    margin-bottom: 10vw;
  }

  .ProjectContainer span {
    /* border: 1px solid red; */
    display: block;
    width: 100%;
    text-align: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 3.8vw;
  }

  .ProjectContainer img {
    width: 100%;
    /* height: 40vw; */
    border-radius: 0.6vw;
  }

  .ProjectContainer p {
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
    text-align: center;
    margin-top: 4.8vw;
  }

  /* .ProjectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 3.8vw 4.8vw 4.8vw 4.8vw;
    border-radius: 0.2vw;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.8);
    margin: 0vw auto 10vw auto;
  }

  .ProjectContainer span {
    font-size: 5.5vw;
    font-weight: 500;
    color: white;
    margin-bottom: 3.8vw;
  }

  .IPASSliderImgs {
    height: 22vw;
    width: 100%;
    height: 100%;
    border-radius: 0.6vw;
  }

  .ProjectContainer p {
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
    text-align: justify;
    margin-top: 4.8vw;
  }

  .FillProjectSpace {
    display: none;
  } */
}