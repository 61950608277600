@media screen and (max-width: 720px) {
  .AboutUsTop {
    box-shadow: 0.1vw 0.4vw 1.5vw rgba(0, 0, 0, 0.7);
    margin: 5vw 5vw 10vw 5vw;
    padding: 5vw 5vw;
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
    text-align: justify;
  }

  .AchievementHead {
    margin-bottom: -8vw;
  }

  .AchievementsSubHead {
    margin: 3vw 5vw 10vw 5vw;
    /* padding: 3.5vw 2vw; */
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
    text-align: justify;
  }

  .GalleryOut {
    /* border: 1px solid red; */
    width: 90%;
    margin: 0vw auto 10vw auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0vw 0.4vw 1.5vw rgba(0, 0, 0, 0.4);
    padding: 5vw 5vw 0vw 5vw;
    border-radius: 0.6vw;
  }

  .GalleryOut img {
    width: 100%;
    /* height: 40vw; */
    margin-bottom: 5vw;
    border-radius: 0.6vw;
    box-shadow: 0vw 0.4vw 1vw rgba(0, 0, 0, 0.5);
  }

  .AlumniContainer {
    /* border: 1px solid red; */
    margin: 2vw 5vw 15vw 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0.6vw;
    box-shadow: 0.1vw 0.4vw 1.5vw rgba(0, 0, 0, 0.7);
    padding: 5vw;
  }

  .AlumniContainer img {
    width: 100%;
    border-radius: 0.6vw;
    margin: 0vw auto;
  }

  .AlumniContainer div {
    /* border: 1px solid green; */
    margin-top: 5vw;
  }

  .AlumniContainer span {
    font-size: 8vw;
    color: white;
    font-weight: 300;
  }

  .AlumniContainer p {
    font-size: 4.5vw;
    color: white;
    margin-top: 4vw;
    font-weight: 300;
  }
}